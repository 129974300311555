<template>
  <div>
    <div class="center" v-if="showLoading">
          <lottie :options="defaultOptionsSplashScreen" :height="350" :width="350" v-on:animCreated="handleAnimationSplashScreen"/>
      </div>
    <div v-else>
      <headerSection></headerSection>
      <div id="main__content" class="">
        <div class="x-main-container">
          <div class="x-main-content">
            <div class="x-promotion-index animation-background">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="x-page-title-component -long-support">
                                <div class="-inner-wrapper">
                                <h1 class="-title" style="font-size:20px;color:#fbc844;">เป่ายิ้งฉุบ</h1>
                                </div>
                            </div>
                            <!-- <p class="-description">**เงื่อนไขฝากครบ 500 บาทต่อวัน ได้รับสิทธิ์ทันที**</p> -->
                        </div>
                    </div>
                </div>
            <!-- 'height':'100vh'-->
              <div class="container">
                  <div class="x-wrapper-right-container">
                      <div id="contact_gl" class="section" style="padding-top: -100px !important;width:100%">
                          <div style="position: absolute; top: 50px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                                  <div style="position: absolute; top: 0px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                                      <lottie :options="defaultOptionsCut" :height="200" :width="200"  v-on:animCreated="handleAnimationCut" />
                                  </div>
                                  <div style="position: absolute; top: 0px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                                      <lottie :options="defaultOptionsPaper" :height="200" :width="200"  v-on:animCreated="handleAnimationPaper" />
                                  </div>
                                  <div style="position: absolute; top: 0px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                                      <lottie :options="defaultOptionsHammer" :height="200" :width="200"  v-on:animCreated="handleAnimationHammer" />
                                  </div>
                          </div>
                          <div style="position: absolute; top: 300px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                                  <div style="position: absolute; top: 0px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                                      <lottie :options="defaultOptions2Cut" :height="200" :width="200"  v-on:animCreated="handleAnimation2Cut" />
                                  </div>
                                  <div style="position: absolute; top: 0px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                                      <lottie :options="defaultOptions2Paper" :height="200" :width="200"  v-on:animCreated="handleAnimation2Paper" />
                                  </div>
                                  <div style="position: absolute; top: 0px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                                      <lottie :options="defaultOptions2Hammer" :height="200" :width="200"  v-on:animCreated="handleAnimation2Hammer" />
                                  </div>
                          </div>
                          <div :style="{'position': 'absolute','top': '200px', 'align-items': 'center', 'justify-content': 'center', 'display': 'flex', 'left': '0', 'right': '0','display':(showResult)?'block':'none'}">
                                  <div :style="{'position': 'absolute', 'top': '0px', 'align-items': 'center', 'justify-content': 'center', 'display': win, 'left': '0', 'right': '0'}">
                                      <!-- <lottie :options="defaultOptionsWin" :height="550" :width="550"  v-on:animCreated="handleAnimationWin" /> -->
                                      <img src="../assets/images/win.png">
                                  </div>
                                  <div :style="{'position': 'absolute', 'top': '0px', 'align-items': 'center', 'justify-content': 'center', 'display': lose, 'left': '0', 'right': '0'}">
                                      <!-- <lottie :options="defaultOptionsLose" :height="550" :width="550"  v-on:animCreated="handleAnimationLose" /> -->
                                      <img src="../assets/images/lose.png">
                                  </div>
                                  <div :style="{'position': 'absolute', 'top': '0px', 'align-items': 'center', 'justify-content': 'center', 'display': draw, 'left': '0', 'right': '0'}">
                                      <!-- <lottie :options="defaultOptionsDraw" :height="550" :width="550"  v-on:animCreated="handleAnimationDraw" /> -->
                                      <img src="../assets/images/draw.png">
                                  </div>
                          </div>
                          <div style="position: absolute; bottom: 80px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                                  <div style="border-style: dashed;">
                                      <img src="../assets/images/1cut.png" width="124" @click="select('cut')">
                                  </div>
                                  <div style="border-style: dashed;">
                                      <img src="../assets/images/2paper.png" width="124" @click="select('paper')">
                                  </div>
                                  <div style="border-style: dashed;">
                                      <img src="../assets/images/3hammer.png" width="124" @click="select('hammer')">
                                  </div>
                          </div>
                          <p class="glow">{{loadingtext}}</p>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <footerSection></footerSection>
      </div>
    </div>
  </div>
</template>
<script>
import firebase, { database } from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import _ from 'lodash'
import router from './../router'
import Lottie from './lottie.vue'
import * as animateSantaWalk from '../assets/paoyingchup.json'
import * as animationData1 from '../assets/1cut.json'
import * as animationData2 from '../assets/2paper.json'
import * as animationData3 from '../assets/3hammer.json'
import moment from 'moment-timezone'
const axios = require('axios').default
require('vue2-animate/dist/vue2-animate.min.css')
// @ is an alias to /src
export default {
name: 'PaoYingChup',
components: {
  headerSection: () => import('./Header.vue'),
  footerSection: () => import('./Footer.vue'),
  lottie: Lottie
},
created () {
  const user = localStorage.getItem('userData')
  if (user) {
    const _user = JSON.parse(user)
    this.$store.dispatch('user/setUser', _user).then(() => {
      this.checkUserId()
    }).catch(err => { console.error(err) })
  } else {
    firebase.auth().signOut()
    localStorage.removeItem('userData')
    router.replace('/login')
  }
  const animateSplashScreen = animateSantaWalk.default
  this.defaultOptionsSplashScreen = { animationData: animateSplashScreen, autoplay: false, loop: true }
  const animage = animationData1.default
  this.defaultOptionsCut = { animationData: animage, autoplay: false, loop: false }
  this.defaultOptions2Cut = { animationData: animage, autoplay: false, loop: false }

  const animage2 = animationData2.default
  this.defaultOptionsPaper = { animationData: animage2, autoplay: false, loop: false }
  this.defaultOptions2Paper = { animationData: animage2, autoplay: false, loop: false }

  const animage3 = animationData3.default
  this.defaultOptionsHammer = { animationData: animage3, autoplay: false, loop: false }
  this.defaultOptions2Hammer = { animationData: animage3, autoplay: false, loop: false }
},
computed: {
  user () {
    return this.$store.state.user.user
  }
},
mounted () {
  const width = screen.width
  if(width <= 480)
    this.backgroundImage = require('../assets/images/Shiba-BG.jpg')
  else
    this.backgroundImage = require('../assets/images/Shiba-BG-Desktop.jpg')
  this.animSplashScreen.play()
  setTimeout(() => {
    this.showLoading = false
    this.animSplashScreen.stop()
  }, 3500)
  // this.getGames()
},
data () {
  return {
    showResult: false,
    win: 'none',
    lose: 'none',
    draw: 'none',
    logoLeft: '',
    logoRight: '',
    angle: 0,
    gameKey: 'paoyingchup',
    showLoading: true,
    pictureUrl: '',
    code: '',
    games_web: [],
    search: '',
    loading: false,
    error: false,
    web: [],
    message: 'Invalid user name or password!',
    point: 0,
    favorites: [],
    animationInfinite: {
      classes: 'fadeIn',
      duration: 5000,
      iteration: 'infinite'
    },
    permission: [],
    defaultOptions: null,
    defaultOptions2: null,
    defaultOptionsSplashScreen: null,

    openGiftData: false,
    canOpen: false,
    percentage: 0,
    loadingtext: '',
    credit: '',
    save: false,
    canvas: null,
    frame: null,
    wheels: null,
    sections: [],
    sectionspoint: [],
    colors: [],
    percent: [],
    backgroundImage: ''
  }
},
methods: {
  select (select) {
    if (this.canOpen) {
      this.$confirm({
        message: `ยืนยันการเลือก${(select === 'cut') ? 'กรรไกร' : (select === 'paper') ? 'กระดาษ' : 'ค้อน'}?`,
        button: {
          yes: 'OK',
          no: 'Cancel'
        },
        callback: async (confirm) => {
          if (confirm) {
            this.canOpen = false
            const com = Math.floor(Math.random() * 3)
            let select2 = ''
            if (com === 0) {
              select2 = 'cut'
              this.animPaper.hide()
              this.animHammer.hide()
              this.animCut.play()
            } else if (com === 1) {
              select2 = 'paper'
              this.animPaper.play()
              this.animHammer.hide()
              this.animCut.hide()
            } else {
              select2 = 'hammer'
              this.animPaper.hide()
              this.animHammer.play()
              this.animCut.hide()
            }

            if (select === 'cut') {
              this.animCut2.play()
              this.animPaper2.hide()
              this.animHammer2.hide()
            } else if (select === 'paper') {
              this.animPaper2.play()
              this.animCut2.hide()
              this.animHammer2.hide()
            } else {
              this.animHammer2.play()
              this.animCut2.hide()
              this.animPaper2.hide()
            }
            console.log(select2 + '==' + select)
            if (select2 === select) {
              console.log('draw')
              setTimeout(() => {
                this.showResult = true
                this.win = 'none'
                this.lose = 'none'
                this.draw = 'flex'
                axios({
                  method: 'post',
                  url: 'https://api.shiba888.bet/qspin/paoyingchup',
                  data: {
                    key: this.user.key,
                    code: this.user.code,
                    credit: 0
                  }
                })
                setTimeout(() => {
                  this.$confirm({
                    message: 'คุณเสมอเสียใจด้วยนะจ๊ะ 😢',
                    button: {
                      yes: 'OK'
                    },
                    callback: confirm => {
                      if (confirm) {
                        router.replace('/')
                      }
                    }
                  })
                }, 2000)
                // this.animDraw.play()
                // this.animWin.hide()
                // this.animLose.hide()
              }, 3000)
            } else if ((select2 === 'cut' && select === 'paper') || (select2 === 'paper' && select === 'hammer') || (select2 === 'hammer' && select === 'cut')) {
              setTimeout(() => {
                this.showResult = true
                this.win = 'none'
                this.lose = 'flex'
                this.draw = 'none'
                axios({
                  method: 'post',
                  url: 'https://api.shiba888.bet/qspin/paoyingchup',
                  data: {
                    key: this.user.key,
                    code: this.user.code,
                    credit: 0
                  }
                })
                setTimeout(() => {
                  this.$confirm({
                    message: 'คุณแพ้เสียใจด้วยนะจ๊ะ 😢',
                    button: {
                      yes: 'OK'
                    },
                    callback: confirm => {
                      if (confirm) {
                        router.replace('/')
                      }
                    }
                  })
                }, 2000)
                // this.animLose.play()
                // this.animWin.hide()
                // this.animDraw.hide()
              }, 3000)
            } else {
              const item = 50
              setTimeout(() => {
                this.showResult = true
                this.win = 'flex'
                this.lose = 'none'
                this.draw = 'none'
                // this.animWin.play()
                // this.animDraw.hide()
                // this.animLose.hide()
                axios({
                  method: 'post',
                  url: 'https://api.shiba888.bet/qspin/paoyingchup',
                  data: {
                    key: this.user.key,
                    code: this.user.code,
                    credit: item
                  }
                })
                setTimeout(() => {
                  this.$confirm({
                    message: `✌️ ปังปุรีเย่!! คุณได้รับ ${item} เหรียญ😘😘`,
                    button: {
                      yes: 'OK'
                    },
                    callback: confirm => {
                      if (confirm) {
                        router.replace('/')
                      }
                    }
                  })
                }, 2000)
              }, 3000)
            }
          }
        }
      })
    }
  },
  handleAnimationCut: function (anim) {
    this.animCut = anim
  },
  handleAnimationPaper: function (anim) {
    this.animPaper = anim
  },
  handleAnimationHammer: function (anim) {
    this.animHammer = anim
  },
  handleAnimation2Cut: function (anim) {
    this.animCut2 = anim
  },
  handleAnimation2Paper: function (anim) {
    this.animPaper2 = anim
  },
  handleAnimation2Hammer: function (anim) {
    this.animHammer2 = anim
  },
  handleAnimationSplashScreen: function (anim) {
    this.animSplashScreen = anim
  },
  //   async getGames () {
  //     this.games_web = (await firebase.database().ref('/spin').once('value')).val()
  //   },
  async checkUserId () {
    await firebase.firestore().collection('spin').doc(this.user.key + '-' + moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + '-paoyingchup').get().then(async (snapshot) => {
      if (snapshot.exists) {
        this.$confirm(
          {
            message: '✌️ ลูกค้าเล่นเป่ายิ้งฉุบของเราไปแล้วนะคะวันนี้ รอวันพรุ่งนี้นะคะ 😘',
            button: {
              yes: 'OK'
            },
            callback: confirm => {
              if (confirm) {
                this.$router.push('/')
              }
            }
          }
        )
      } else {
        await firebase.firestore().collection('daily_deposit').doc(this.user.key + '-' + moment().tz('Asia/Bangkok').add(-1, 'days').format('YYYY-MM-DD')).get().then(async (doc) => {
          if (doc.exists) {
            if (doc.data().cashback >= 1000) {
              this.canOpen = true
            } else {
              const less = 1000 - Number(doc.data().cashback)
              // this.loadingtext = `เติมเงินอีก ${less}(250) ได้เล่นเลยค่ะ!😘😘`;
              this.loadingtext = `ยอดเสียเมื่อวานขาดอีก ${less}(1000) ค่ะ!😘😘`
            }
          } else {
            this.loadingtext = 'ยอดเสียเมื่อวานขาดอีก 1000(1000) ค่ะ!😘😘'
          }
        })
      //   await firebase.firestore().collection('history').where('userkey', '==', this.user.key).where('type', '==', 'ฝาก').where('status', '==', 'ดำเนินการแล้ว').where('createdate', '>=', moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + ' 00:00:00').where('createdate', '<=', moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + ' 23:59:59').get().then(async (snapshot) => {
      //     let credit = 0
      //     snapshot.forEach((doc) => {
      //       credit += Number(doc.data().qty)
      //     })
      //     if (credit >= 500) {
      //       this.canOpen = true
      //     } else {
      //       const less = 500 - Number(credit)
      //       this.loadingtext = `เติมเงินอีก ${less}(500) ได้เล่นเลยค่ะ!😘😘`
      //     }
      //   })
      }
    })
  },
  checkCssBox (game) {
    if (!game.status) {
      return 'box-shadow: 0 0 12px 5px #cccccc;border: 2px solid #cccccc;'
    }
  },
  checkCss (game) {
    if (!this.permission || !this.permission[game.key]) {
      return 'filter: grayscale(100%);'
    }
    // const index = _.findIndex(this.favorites, function(o) { return o == code; });
    // if (index>-1) {
    //   return '';
    // } else {
    //   return 'filter: grayscale(100%);';
    // }
  }
}
}
</script>
<style scoped>
.glow {
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
    }

    @-webkit-keyframes glow {
    from {
        text-shadow: 0 0 10px #dbc47a, 0 0 20px #dbc47a, 0 0 30px #dbc47a, 0 0 40px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a;
    }

    to {
        text-shadow: 0 0 10px #dbc47a, 0 0 20px #dbc47a, 0 0 30px #dbc47a, 0 0 40px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a, 0 0 10px #dbc47a;
    }
}
.center {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#contact_gl {
  min-height: 600px;
  color: #333;
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
    /*background: -moz-radial-gradient(center, ellipse cover, #f7f7f7 0%, #d1d1d1 100%);
    background: -webkit-radial-gradient(center, ellipse cover, #f7f7f7 0%,#d1d1d1 100%);
    background: radial-gradient(ellipse at center, #f7f7f7 0%,#d1d1d1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#d1d1d1',GradientType=1 );*/
}
#contact_gl::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}
.boxlogin {
    background: #fff;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0 0 40px #000;
    color: #8b8c8d;
    font-size: 0;
}
.boxlogin .box-part {
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 100%;
}
.boxlogin, .box .box-part {
    box-sizing: border-box;
}
.boxlogin .partition {
    width: 100%;
    height: 100%;
}
.boxlogin .partition .partition-title {
    box-sizing: border-box;
    padding: 30px;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 300;
}
.boxlogin .partition .partition-form {
    padding: 0 20px;
    box-sizing: border-box;
}
.boxlogin .button-set {
    margin-bottom: 8px;
}
.boxlogin .github-btn {
    border-color: #dba226;
    color: #dba226;
}
.boxlogin .large-btn {
    width: 100%;
    background: #fff;
}
.boxlogin button {
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: Open Sans,sans-serif;
    font-weight: 400;
    min-width: 140px;
    margin-top: 8px;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: all .1s;
    font-size: 10px;
    outline: none;
}
.spin {   padding: 0px;
          margin: 0px;
          overflow: hidden;
          height: 100%;
/*background: rgb(255,204,92,1);
background: radial-gradient(circle, rgba(255,204,92,1) 49%, rgba(34,193,195) 100%);*/
      }
 .p2 {
     letter-spacing: 0;
     text-shadow: 0 0 80px rgba(255, 255, 255, .5);
    /* Clip Background Image */
     background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
     -webkit-background-clip: text;
     background-clip: text;
    /* Animate Background Image */
     -webkit-text-fill-color: transparent;
     -webkit-animation: aitf 80s linear infinite;
    /* Activate hardware acceleration for smoother animations */
     -webkit-transform: translate3d(0, 0, 0);
     -webkit-backface-visibility: hidden;
}
/* Animate Background Image */
 @-webkit-keyframes aitf {
     0% {
         background-position: 0% 50%;
    }
     100% {
         background-position: 100% 50%;
    }
}
</style>
